import React from 'react';
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import { Col, Row, Typography } from 'antd';
import { CheckboxGroupField, InputField } from 'common/fields';
import { Tooltip } from 'common/components';
import { withTranslation, WithTranslation } from 'react-i18next';
import { EnhancedCheckboxOptionType } from 'common/fields/CheckboxGroup';
import { PHONE_REGEX } from 'domain-constants';
import { ReadOnlyUserRole } from 'generated/new-main/models';
import qa from 'qa-selectors';
import FormActions from 'common/components/FormActions';
import { getRoleQaKey } from 'common/utils/components/RoleName';

export interface UserProfileFormModel {
    name?: string;
    surname?: string;
    email?: string;
    phone?: string;
    username?: string;
    pin?: string;
    roles?: string[];
}

interface Props extends WithTranslation {
    initialValues?: Partial<UserProfileFormModel>;
    roles: EnhancedCheckboxOptionType[];
    mode: 'EDIT' | 'CREATE';
    originalRoles?: ReadOnlyUserRole[];
    onSubmit?: (values: UserProfileFormModel) => Promise<boolean>;
    onChange?: (values: UserProfileFormModel) => void;
    onCancel?: () => void;
}

type onChangeProps = {
    values: UserProfileFormModel;
    onChange?: (values: UserProfileFormModel) => void;
};
const OnChangeComponent = (props: onChangeProps) => {
    const { values, onChange } = props;
    React.useEffect(() => {
        onChange?.(values);
    }, [values, onChange]);

    return null;
};

function UserProfileForm(props: Props) {
    const driverRole = props.originalRoles?.find(r => r.label.toLocaleLowerCase() === 'driver');

    const schema = Yup.object().shape({
        name: Yup.string()
            .required(props.t('common.required'))
            .matches(/^[А-яЁёa-zA-ZÀ-ž ]*[А-яЁёa-zA-ZÀ-ž]+[А-яЁёa-zA-ZÀ-ž ]*$/, props.t('validator.invalid_format')),
        surname: Yup.string()
            .required(props.t('common.required'))
            .matches(/^[А-яЁёa-zA-ZÀ-ž ]*[А-яЁёa-zA-ZÀ-ž]+[А-яЁёa-zA-ZÀ-ž ]*$/, props.t('validator.invalid_format')),
        email: Yup.string()
            .when('roles', {
                is: (roles: number[]) => {
                    if (!roles) {
                        return false;
                    }
                    const found = roles.find(roleId => {
                        const foundGroup = props.roles.find(role => role.value === roleId);
                        return foundGroup?.value === driverRole?.id;
                    });
                    return found && roles.length === 1;
                },
                then: Yup.string().notRequired(),
                otherwise: Yup.string().required(props.t('common.required'))
            })
            .email(props.t('common.invalidFormat')),
        phone: Yup.string().notRequired().matches(PHONE_REGEX, props.t('validator.invalid_phone_international_format')),
        roles: Yup.array(Yup.string().required()).min(1, props.t('common.required')),
        pin: Yup.string()
            .when('roles', {
                is: (roles: number[]) => {
                    if (!roles) {
                        return false;
                    }
                    const found = roles.find(roleId => {
                        const foundGroup = props.roles.find(role => role.value === roleId);
                        return foundGroup?.value === driverRole?.id;
                    });
                    return found;
                },
                then: Yup.string().required(props.t('common.required')),
                otherwise: Yup.string().notRequired()
            })
            .min(0)
            .max(8, props.t('validator.not_in_range'))
            .matches(/^[0-9]+$/, props.t('validator.invalid_format')),
        username:
            props.mode === 'CREATE'
                ? Yup.string()
                      .required(props.t('common.required'))
                      .matches(/^[^\s].+[^\s]$/, props.t('validator.invalid_format'))
                : Yup.string()
                      .notRequired()
                      .matches(/^[^\s].+[^\s]$/, props.t('validator.invalid_format'))
    });

    function handleSubmit(values: UserProfileFormModel) {
        return props.onSubmit?.(values);
    }

    return (
        <Formik<UserProfileFormModel>
            initialValues={{
                name: props.initialValues?.name,
                surname: props.initialValues?.surname,
                email: props.initialValues?.email ?? '',
                roles: props.initialValues?.roles,
                phone: props.initialValues?.phone ?? '',
                username: props.initialValues?.username,
                pin: props.initialValues?.pin
            }}
            onSubmit={handleSubmit}
            validationSchema={schema}
            validateOnBlur={true}
            validateOnChange={true}
        >
            {(formik: FormikProps<UserProfileFormModel>) => {
                return (
                    <>
                        <OnChangeComponent values={formik.values} onChange={props.onChange} />
                        <Form className="user-form">
                            {/* Prevent browser to autocomplete fields */}
                            <input name="username" type="username" style={{ opacity: 0, position: 'absolute' }} />
                            <input name="email" disabled={props.mode === 'EDIT'} type="email" style={{ opacity: 0, position: 'absolute' }} />
                            <input name="password" type="password" style={{ opacity: 0, position: 'absolute' }} />
                            {/* Prevent browser to autocomplete fields */}
                            <Row gutter={24}>
                                <Col xl={24} xxl={10}>
                                    <label>{props.t('common.name')}</label>
                                </Col>
                                <Col xl={24} xxl={14}>
                                    <InputField
                                        name="name"
                                        placeholder={props.t('common.name')}
                                        qa={qa.users.form.inputName}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col xl={24} xxl={10}>
                                    <label>{props.t('common.surname')}</label>
                                </Col>
                                <Col xl={24} xxl={14}>
                                    <InputField
                                        name="surname"
                                        placeholder={props.t('common.surname')}
                                        qa={qa.users.form.inputSurname}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col xl={24} xxl={10}>
                                    <label>{props.t('common.email')}</label>
                                </Col>
                                <Col xl={24} xxl={14}>
                                    <InputField
                                        name="email"
                                        disabled={props.mode === 'EDIT'}
                                        placeholder={props.t('common.email')}
                                        qa={qa.users.form.inputEmail}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col xl={24} xxl={10}>
                                    <label>{props.t('common.phone')}</label>
                                </Col>
                                <Col xl={24} xxl={14}>
                                    <InputField
                                        name="phone"
                                        placeholder={props.t('common.phone')}
                                        qa={qa.users.form.inputPhone}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col xl={24} xxl={10}>
                                    <label>{props.t('common.username')}</label>
                                </Col>
                                <Col xl={24} xxl={14}>
                                    <InputField
                                        name="username"
                                        placeholder={props.t('common.username')}
                                        qa={qa.users.form.inputUsername}
                                    />
                                </Col>
                            </Row>
                            {formik.values.roles?.find(roleId => {
                                const foundGroup = props.roles.find(r => r.value === roleId);
                                return foundGroup && foundGroup.value === driverRole?.id;
                            }) && (
                                <Row gutter={24}>
                                    <Col xl={24} xxl={10}>
                                        <label>{props.t('common.pin')}</label>
                                    </Col>
                                    <Col xl={24} xxl={14}>
                                        <InputField
                                            name="pin"
                                            placeholder={props.t('common.pin')}
                                            qa={qa.users.form.inputPin}
                                        />
                                    </Col>
                                </Row>
                            )}
                            <Row gutter={24}>
                                <Col xl={24} xxl={10}>
                                    <label>{props.t('NavBar.roles')}</label>
                                </Col>
                                <Col xl={24} xxl={14}>
                                    <CheckboxGroupField
                                        name="roles"
                                        options={props.roles.map(d => {
                                            const qaKey = getRoleQaKey(d.label?.toString() ?? '');
                                            return {
                                                ...d,

                                                label: (
                                                    <Tooltip placement="topLeft" overlay={d.label}>
                                                        <Typography.Text ellipsis>{d.label}</Typography.Text>
                                                    </Tooltip>
                                                ),
                                                qa: qaKey ? qa.users.form.roles[qaKey] : undefined
                                            };
                                        })}
                                        className="user-form-roles"
                                        qa={qa.users.form.inputRoles}
                                    />
                                </Col>
                            </Row>
                            <FormActions
                                cancelQa={qa.users.form.btnFormCancel}
                                submitQa={qa.users.form.btnFormSubmit}
                                submitLoading={formik.isSubmitting}
                                onCancelClick={props.onCancel}
                            />
                        </Form>
                    </>
                );
            }}
        </Formik>
    );
}

export default withTranslation()(UserProfileForm);
